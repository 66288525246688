<template>
  <app-card-item class="p-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
    <form-group
      type="date"
      name="start_date"
      margins="mb-0"
      :hide-errors="true"
      @changed="(value) => handleFilterChange('start_date', value)"
    />

    <form-group
      type="date"
      name="end_date"
      margins="mb-0"
      :hide-errors="true"
      @changed="(value) => handleFilterChange('end_date', value)"
    />
  </app-card-item>

  <div class="flex justify-center mt-4">
    <app-button
      feature="reset"
      :label="t('common.actions.reset')"
      class="mr-5"
      padding="py-2 px-6"
      emphasis="low"
      font-color-classes="text-theme-900"
      @click="handleResetFilters"
    />

    <app-button
      feature="apply"
      :label="t('common.actions.apply')"
      padding="py-2 px-6"
      @click="handleFiltersApplied"
    />
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'

import useFilters from '@shared/hooks/filters'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const emits = defineEmits([
  'filters-applied',
])

const { t } = useI18n()

// ---------- FILTERS ----------

const form = inject('paymentsIndexFiltersForm')

const {
  handleResetFilters,
  handleFiltersApplied,
  handleFilterChange,
} = useFilters('payments', form.value, { emits })
</script>
