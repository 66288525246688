<template>
  <div
    ref="appTableContainerRef"
  >
    <app-section-title
      :title="t('models.bank_transfer', 2)"
      class="mb-3.5"
    />

    <app-table
      :headers="transfersHeaders"
      :data="transfersTableData"
      :loading="transfersLoading"
    />
  </div>

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    :element-to-scroll-to="appTableContainerRef"
    class="mt-5"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  fetchBankTransfersInstitutions as apiFetchBankTransfersInstitutions,
} from '@shared/http/api'
import useDatetime from '@shared/hooks/datetime'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppExternalLink from '@shared/components/ui/AppExternalLink.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'

const { t, locale } = useI18n()
const { formatDateShort } = useDatetime()

// ---------- TRANSFERS ----------

const transfers = ref([])
const transfersLoading = ref(true)
const nextPageFetching = ref(false)

function fetchBankTransfersInstitutions(nextPage = false) {
  if (nextPage === false) {
    transfersLoading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
  }

  apiFetchBankTransfersInstitutions(params)
    .then((response) => {
      transfers.value = response.data.data ?? []

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      transfersLoading.value = false
    })
}

const transfersHeaders = [
  { label: t('validation.attributes.date') },
  { label: t('validation.attributes.actions') },
]

const transfersTableData = computed(() => (
  transfers?.value?.map((transfer) => ({
    created_at: formatDateShort(transfer.attributes?.created_at),
    title: {
      component: AppExternalLink,
      props: {
        link: locale.value === 'fr' ? transfer.attributes.fr_file_url : transfer.attributes.en_file_url,
        text: t('common.actions.download'),
      },
    },
  }))
))

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const links = ref([])
const page = ref(1)

function handleChangePage(value) {
  page.value = value
  fetchBankTransfersInstitutions()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchBankTransfersInstitutions()
})
</script>
