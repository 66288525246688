<template>
  <teleport-wrapper to="#mainTitle">
    {{ t('breadcrumbs.finances.finances') }}
  </teleport-wrapper>

  <router-view />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
