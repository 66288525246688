<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Received payments -->
  <div class="mb-8">
    <payment-received
      v-if="paymentsReceivedAccess"
    />
  </div>

  <!-- Received by institution payments -->
  <div class="mb-8">
    <payment-received-by-institution
      v-if="paymentsReceivedByInstitutionAccess"
    />
  </div>

  <!-- Transfers to institution -->
  <div class="mb-8">
    <bank-transfers-institutions-table
      v-if="paymentsReceivedByInstitutionAccess"
    />
  </div>

  <!-- Issued payments -->
  <div class="mt-8">
    <payment-issued
      v-if="paymentsIssuedAccess"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import breadcrumbFinance from '@extranet/breadcrumbs/finance'
import useAccess from '@extranet/hooks/access'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import BankTransfersInstitutionsTable from '@extranet/components/resources/bank_transfers_institutions/BankTransfersInstitutionsTable.vue'
import PaymentReceived from '@extranet/components/resources/payment/PaymentReceived.vue'
import PaymentReceivedByInstitution from '@extranet/components/resources/payment/PaymentReceivedByInstitution.vue'
import PaymentIssued from '@extranet/components/resources/payment/PaymentIssued.vue'

// ---------- ACCESS ----------

const {
  paymentsReceivedByInstitutionAccess,
  paymentsReceivedAccess,
  paymentsIssuedAccess,
} = useAccess()

// ---------- BREADCRUMBS ----------

const { dashboard } = breadcrumbFinance()

const breadcrumbs = computed(() => (
  dashboard()
))
</script>
