import { useI18n } from 'vue-i18n'

export default function breadcrumbFinance() {
  const { t } = useI18n()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function dashboard() {
    return [
      {
        text: t('breadcrumbs.finances.finances'),
        link: false,
        to: '/finances',
      },
    ]
  }

  return {
    dashboard,
  }
}
